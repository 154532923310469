import { useState, useContext, useEffect } from "react"
import { Actions } from "../../../Reducer/reducer"
import { CredentialsContext } from "../../../API/CredentialsContext"
import Wrapper from "../../../Common components/Wrapper"

const Upload = () => {

    const [uploadState, setUploadState] = useState({
        file: {},
        credential: {},
        uploadedFileData: []
    })
    const { state, dispatch } = useContext(CredentialsContext)
    const authToken = localStorage.getItem('authToken')
    const users = state?.credentials || []
    const isUploadButtonDisabled =
        Object.keys(uploadState.credential).length === 0 ||
        document.getElementById("file").value === ""

    const handleState = (event) => {
        const { name, value, files } = event.target
        if (name === 'credential') {
            setUploadState(prevState => (
                {
                    ...prevState,
                    [name]: users.find(u => u.id === +value)
                }
            ))
        } else {
            setUploadState(prevState => (
                {
                    ...prevState,
                    [name]: files[0]
                }
            ))
        }
    }

    const handleFileResponse = async () => {
        const response = await fetch(`https://app.e-waybill.in/web-app/job-file`, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'X-Auth-Token': authToken,
            },
        })
        const data = await response.json()
        setUploadState((prev) => ({ ...prev, uploadedFileData: data }))
    }

    const handleFileUpload = async () => {
        try {
            dispatch({ type: Actions.ShowLoading })

            if (!uploadState.file) {
                alert("Upload File")
                return
            }

            const formData = new FormData()
            formData.append('file', uploadState.file)
            formData.append('gstin', uploadState.credential.gstNumber)

            const response = await fetch(`https://app.e-waybill.in/web-app/job-file`, {
                method: 'POST',
                headers: {
                    'X-Auth-Token': authToken,
                },
                body: formData
            })

            if (response.ok) {
                console.log("File uploaded successfully")
                handleFileResponse()
            } else {
                console.error("Error in getting response from api")
            }
        }
        catch (error) { console.error('Error in uploading file:', error) }
        finally { dispatch({ type: Actions.HideLoading }) }
    }

    useEffect(() => {
        const handleFileResponse = async () => {
            const response = await fetch(`https://app.e-waybill.in/web-app/job-file`, {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                    'X-Auth-Token': authToken,
                },
            })
            const data = await response.json()
            setUploadState((prev) => ({ ...prev, uploadedFileData: data }))
        }
        handleFileResponse()
    }, [authToken])

    return (
        <Wrapper>
            <div className="col-lg-3 m-3">
                <h4>Upload Today's File</h4>
                <div className="form-group mb-3">
                    <label htmlFor="credential" className="mb-2">Credential</label>
                    <select
                        className="form-select"
                        id="credential"
                        name="credential"
                        onChange={handleState}
                    >
                        <option>Select</option>
                        {
                            users.map((user, index) => {
                                return (
                                    <option key={index} value={user.id}>{user.gstNumber}</option>
                                )
                            })
                        }
                    </select>
                </div>
                <div className="form-group my-3">
                    <label className="mb-2" htmlFor="gstin">Choose File To Upload</label>
                    <input
                        className="form-control"
                        id="file"
                        name="file"
                        type="file"
                        onChange={handleState}
                    />
                </div>
                <button
                    className="btn btn-primary"
                    onClick={handleFileUpload}
                    disabled={isUploadButtonDisabled}
                >
                    Upload File
                </button>
            </div>
            <div className="col-lg-7 m-5">
                <h4>Upload History</h4>
                <table className="table table-borderless table-striped mt-4 mb-5">
                    <thead>
                        <tr className="text-uppercase table-dark">
                            <th>SL.NO</th>
                            <th>File Name</th>
                            <th>Uploaded By</th>
                            <th>Time Uploaded</th>
                        </tr>
                    </thead>
                    <tbody>
                        {
                            uploadState.uploadedFileData.map((item, index) => {
                                const time = new Date(item.uploadedAt).toLocaleTimeString()
                                const date = new Date(item.uploadedAt).toDateString()
                                return (
                                    <tr key={index}>
                                        <td>{index + 1}</td>
                                        <td>{item.fileName}</td>
                                        <td>{item.uploadedBy}</td>
                                        <td>{time} {date}</td>
                                    </tr>
                                )
                            })
                        }
                    </tbody>
                </table>
                <div className="alert alert-danger my-2">
                    NOTE: Upload must be done before 8pm
                </div>
            </div>
        </Wrapper>
    )
}
export default Upload